import { signOut } from "firebase/auth";
import React, { useContext, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import CustomLink from "../CustomeLink/CustomeLink";
import CustomLinkMobile from "../CustomeLink/CustomeLinkMobile";
import userPhoto from "../../assets/img/user.png";
import { toast } from "react-toastify";
import logo from "../../assets/img/logo.png";
import { ReactComponent as CartIcon } from "../../assets/svg/cart.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCodeCompare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { CartContext } from "../../Context/CartContext";
import { WishListContext } from "../../Context/WishListContext";
import { CompareListContext } from "../../Context/CompareListContext";

const Navbar = () => {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartItem] = useContext(CartContext);
  const [wishItem] = useContext(WishListContext);
  const [compareList] = useContext(CompareListContext);

  const Links = [
    { name: "Home", link: "/" },
    { name: "Service", link: "/services" },
    { name: "Blog", link: "/blog" },
    { name: "Shop", link: "/shop" },
  ];

  const logOut = () => {
    toast.success("Logout Successfully");
    signOut(auth);
  };

  return (
    <nav className="sticky top-0 z-40 bg-gray-100 shadow-md dark:bg-black">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-6 lg:h-9" />
          </Link>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex items-center space-x-6">
          {Links.map((link) => (
            <li key={link.name}>
              <CustomLink to={link.link} className="text-gray-700 hover:text-orange-600 font-bold">
                {link.name}
              </CustomLink>
            </li>
          ))}
        </ul>


        {/* Right Section: Cart, Wishlist, Compare, User */}
        <div className="flex items-center space-x-4">
        
        <div className="mr-5 flex items-center relative gap-2">
            {/* <!-- Icon --> */}

            <span
              onClick={() => navigate("/dashboard/cartlist")}
              className="
                      text-gray-500
                      hover:text-gray-700
                      focus:text-gray-700
                      mr-4
                      dropdown-toggle
                      hidden-arrow
                      flex items-center
                    "
              id="dropdownMenuButton1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <CartIcon />
              <span className="text-white bg-red-700 absolute rounded-full text-xs -mt-3 ml-3 py-0 px-1.5">
                {cartItem?.length}
              </span>
            </span>

            <span
              onClick={() => navigate("/dashboard/wishlist")}
              className="
                      text-gray-500
                      hover:text-gray-700
                      focus:text-gray-700
                      mr-4
                      dropdown-toggle
                      hidden-arrow
                      flex items-center
                    "
              id="dropdownMenuButton1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ion-icon
                name="heart-outline"
                style={{ fontSize: "22px" }}
              ></ion-icon>
              <span className="text-white bg-red-700 absolute rounded-full text-xs -mt-3 ml-3 py-0 px-1.5">
                {wishItem?.length}
              </span>
            </span>

            <a
              onClick={() => navigate("/dashboard/comparelist")}
              className="
                      text-gray-500
                      hover:text-gray-700
                      focus:text-gray-700
                      mr-2
                      dropdown-toggle
                      hidden-arrow
                      flex items-center
                    "
              href="#"
              id="dropdownMenuButton1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faCodeCompare} className="text-lg " />
              <span className="text-white bg-red-700 absolute rounded-full text-xs -mt-3 ml-3 py-0 px-1.5">
                {compareList?.length}
              </span>
            </a>
          </div>

          {/* User Profile */}
          {user && (
            <div className="relative">
              <img
                src={user?.photoURL || userPhoto}
                className="h-8 w-8 rounded-full cursor-pointer"
                onClick={() => setOpen(!open)}
                alt="User"
              />
              {open && (
                <ul className="absolute right-0 mt-4 py-2 bg-white rounded shadow-lg">
                  <li>
                    <Link to="/dashboard" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={logOut}
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>

        
        {/* Mobile Menu Icon */}
        <div className="lg:hidden">
          <FontAwesomeIcon
            icon={faBars}
            className="text-xl text-orange-600 cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>

        {/* Mobile Dropdown */}
        {open && (
          <div className="lg:hidden absolute top-16 left-0 w-full bg-gray-100 shadow-md">
            <ul className="flex flex-col items-center">
              {Links.map((link) => (
                <li key={link.name} className="py-2">
                  <CustomLinkMobile to={link.link} className="text-gray-700 hover:text-orange-600 font-bold">
                    {link.name}
                  </CustomLinkMobile>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
