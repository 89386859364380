import React from "react";
import {
  GET_PRODUCTS,
  GET_CHECKOUT,
} from "../type";
import {
  GetProducts,
  GetCheckOutData,
} from "../../api/axiosRequest";

const GetApiAction = () => {
  return function (dispatch) {
    return GetProducts().then((res) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data,
      });
    });
  };
};

const getApiCheckOutAction = () => {
  return function (dispatch) {
    return GetCheckOutData().then((res) => {
      console.log(res);
      dispatch({
        type: GET_CHECKOUT,
        payload: res.data,
      });
    });
  };
};

export {
  GetApiAction,
  getApiCheckOutAction,
};
