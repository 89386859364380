import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

export const CartContext = React.createContext();

export const CartContextProvider = (props) => {
  const [cartItem, setCart] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    // Load cart from local storage on initial render
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    
    // Filter cart items based on user email
    if (user) {
      const filteredCart = storedCart.filter(item => item.email === user.email);
      setCart(filteredCart);
    } else {
      setCart([]); // Clear cart if there's no user
    }
  }, [user]); // Re-run when user changes



  return (
    <CartContext.Provider value={[cartItem, setCart]}>
      {props.children}
    </CartContext.Provider>
  );
};
