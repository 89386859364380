import React, { useState } from "react";
import img1 from "../../assets/img/slider1.jpg";
import img2 from "../../assets/img/slider2.jpg";
import img3 from "../../assets/img/slider3.jpg";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [img1, img2, img3];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  return (
    <section>
      <div className="relative">
        <div className="carousel-item">
          <img
            src={images[currentSlide]}
            className="block w-full"
            alt="Slide"
            style={{ height: "550px" }}
          />
          <div className="absolute inset-0 bg-black opacity-75 flex justify-center items-center">
            <div className="text-center text-white px-6 py-6 md:py-0 md:px-12 max-w-[800px]">
              <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight leading-tight mb-6">
                WHOMEN FASHION
              </h2>
              <button
                type="button"
                className="inline-block px-8 py-3 bg-orange-600 text-white font-medium text-sm leading-tight uppercase shadow-md hover:bg-orange-700 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg focus:outline-none transition duration-150 ease-in-out"
              >
                SHOP NOW
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2"
        >
          &#10094; {/* Left Arrow */}
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2"
        >
          &#10095; {/* Right Arrow */}
        </button>
      </div>
    </section>
  );
};

export default Slider;
