import React, { createContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

export const WishListContext = createContext();

export const WishListProvider = (props) => {
  const [wishItem, setWishList] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    // Load wishlist from local storage on initial render
    const storedWishList = JSON.parse(localStorage.getItem('wishlist')) || [];
    
    // Filter wishlist items based on user email
    if (user) {
      const filteredWishList = storedWishList.filter(item => item.email === user.email);
      setWishList(filteredWishList);
    } else {
      setWishList([]); // Clear wishlist if there's no user
    }
  }, [user]); // Re-run when user changes


  return (
    <WishListContext.Provider value={[wishItem, setWishList]}>
      {props.children}
    </WishListContext.Provider>
  );
};
