import axios from "axios";

export async function AxiosRequest(url, method, headers, params) {
  return params
    ? axios({
        url: url,
        method: method,
        headers: headers,
        data: params,
        timeout: 1000,
      })
    : axios({
        url: url,
        method: method,
        headers: headers,
        data: {},
        timeout: 1000,
      });
}

const GetProducts = async () => {
  const getProductValue = await axios({
    url: "https://eshopper.fouzderit.com/api/products",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
    data: {},
  });

  return getProductValue.data;
};

const GetCheckOutData = async () => {
  const getCheckOutValue = await axios({
    url: "https://eshopper.fouzderit.com/api/checkout",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return getCheckOutValue;
};



export {
  GetProducts,
  GetCheckOutData,
};
