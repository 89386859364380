export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_CARTLIST = "ADD_CARTLIST";
export const UPDATE_CARTLIST = "UPDATE_CARTLIST";
export const UPDATE_ALL_CARTLIST = "UPDATE_ALL_CARTLIST";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const ADD_COMPARELIST = "ADD_COMPARELIST";
export const ADD_CHECKOUT = "ADD_CHECKOUT";
export const GET_CARTLIST = "GET_CARTLIST";
export const GET_WISHLIST = "GET_WISHLIST";
export const GET_COMPARELIST = "GET_COMPARELIST";
export const GET_CHECKOUT = "GET_CHECKOUT";
export const DELTE_CARTLIST = "DELTE_CARTLIST";
export const DELTE_COMPARELIST = "DELTE_COMPARELIST";
export const DELTE_WISHLIST = "DELTE_WISHLIST";
export const DELTE_CHECKOUT = "DELTE_CHECKOUT";
