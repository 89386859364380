import React, { createContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

export const CompareListContext = createContext();

export const CompareListProvider = (props) => {
  const [compareList, setCompareList] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    // Load compare list from local storage on initial render
    const storedCompareList = JSON.parse(localStorage.getItem('compareList')) || [];
    
    // Filter compare list items based on user email
    if (user) {
      const filteredCompareList = storedCompareList.filter(item => item.email === user.email);
      setCompareList(filteredCompareList);
    } else {
      setCompareList([]); // Clear compare list if there's no user
    }
  }, [user]); // Re-run when user changes

  return (
    <CompareListContext.Provider value={[compareList, setCompareList]}>
      {props.children}
    </CompareListContext.Provider>
  );
};
