import React from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import MainSidebar from "../MainSidebar/MainSidebar";
const Dashboard = () => {
  return (
    <div className="dark:bg-black">
      <div>
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-2 z-0">
            <MainSidebar></MainSidebar>
          </div>
          <div className="col-span-12 lg:col-span-10 border">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
