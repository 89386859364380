import Product from "../../component/Product/Product";
import {
  GET_PRODUCTS,
  GET_CHECKOUT,
} from "../type";

const initialState = {
  products: [],
  cartitem: [],
  checkOutValue: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };


    case GET_CHECKOUT:
      return {
        ...state,
        checkOutValue: action.payload,
      };


    default:
      return state;
  }
};

export default Reducer;
