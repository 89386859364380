import React, { useEffect, useState } from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Shop.css";
import Product from "../../component/Product/Product";
import PageBanner from "../../component/PageBanner/PageBanner";
import { useDispatch, useSelector } from "react-redux";
import { GetApiAction } from "../../redux/action/Action";

const Shop = () => {
  const product = useSelector((state) => state.Reducer.products);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(GetApiAction());
  }, []);

  return (
    <div className="dark:bg-black py-10">
      <PageBanner page="Shop" />
      <div className="container-fluid mx-auto px-4 md:px-20 py-30 dark:bg-black pt-8">
        <h1 className="text-3xl text-stone-900 font-bold uppercase text-center mx-auto mb-12 dark:text-slate-200 pt-8">
          All Products
        </h1>
       
        <div class="grid md:grid-cols-4 lg:grid-cols-5 gap-4 lg:gap-x-4 ">
          {product?.map((item) => {
            return (
              <React.Fragment key={item._id}>
                <Product img={product} item={item} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Shop;
